import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 
import {api_base_url, base_url} from '../common/Constants';

 
class WhyChooseHome extends React.Component{

    constructor(){
        super();

        
        this.state = { 
            page_content:[],
            loading : 1,
            settings:{}
        }
        

    }

    componentDidMount()
    {
        axios.get(api_base_url+"settings").then(response=>{
            this.setState({settings:response.data}) ;
        });
        
            axios.get(api_base_url+"page_content").then(response=>{
                this.setState({page_content:response.data}); 
                this.setState({loading:0})
            }); 
        
    }


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className="why-choose-one section-space-two" id="why-choose">
        <div className="container">
            <div className="sec-title sec-title--center">

                <img src="assets/images/shapes/sec-title-s-1.png" alt={this.state.page_content[2].title} className="sec-title__img" />


                <h6 className="sec-title__tagline">{this.state.page_content[2].title}</h6> 
                
                <h2 className="sec-title__title"><div dangerouslySetInnerHTML={{ __html: this.state.page_content[2].description}} />  </h2> 
            </div> 
            <div className="row gutter-y-30 align-items-center">
                <div className="col-lg-4 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="00ms">
                    <div className="why-choose-one__content">
                        <div className="why-choose-one__item">
                            <div className="why-choose-one__item__icon">
                                <span className="icon-grass"></span>
                            </div> 
                            <div className="why-choose-one__item__content">
                                <h4 className="why-choose-one__item__title">{this.state.page_content[3].title}</h4> 
                                <p className="why-choose-one__item__text">{this.state.page_content[3].description}</p> 
                            </div> 
                        </div> 
                        <div className="why-choose-one__item">
                            <div className="why-choose-one__item__icon">
                                <span className="icon-fast-delivery"></span>
                            </div> 
                            <div className="why-choose-one__item__content">
                                <h4 className="why-choose-one__item__title">{this.state.page_content[4].title}</h4> 
                                <p className="why-choose-one__item__text">{this.state.page_content[4].description}</p> 
                            </div> 
                        </div> 
                        <div className="why-choose-one__item">
                            <div className="why-choose-one__item__icon">
                                <span className="icon-achievement"></span>
                            </div> 
                            <div className="why-choose-one__item__content">
                            <h4 className="why-choose-one__item__title">{this.state.page_content[5].title}</h4> 
                            <p className="why-choose-one__item__text">{this.state.page_content[5].description}</p> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
                <div className="col-lg-4 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="400ms">
                    <div className="why-choose-one__image">
                        <img src={this.state.page_content[2].image} alt="why-choose-shape" />
                    </div> 
                </div>
                <div className="col-lg-4 wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="00ms">
                    <div className="why-choose-one__content">
                        <div className="why-choose-one__item">
                            <div className="why-choose-one__item__icon">
                                <span className="icon-no-preservatives"></span>
                            </div> 
                            <div className="why-choose-one__item__content">
                            <h4 className="why-choose-one__item__title">{this.state.page_content[6].title}</h4> 
                            <p className="why-choose-one__item__text">{this.state.page_content[6].description}</p>  
                            </div> 
                        </div> 
                        <div className="why-choose-one__item">
                            <div className="why-choose-one__item__icon">
                                <span className="icon-healthcare-1"></span>
                            </div> 
                            <div className="why-choose-one__item__content">
                            <h4 className="why-choose-one__item__title">{this.state.page_content[7].title}</h4> 
                            <p className="why-choose-one__item__text">{this.state.page_content[7].description}</p> 
                            </div> 
                        </div> 
                        <div className="why-choose-one__item">
                            <div className="why-choose-one__item__icon">
                                <span className="icon-meat"></span>
                            </div> 
                            <div className="why-choose-one__item__content">
                            <h4 className="why-choose-one__item__title">{this.state.page_content[8].title}</h4> 
                            <p className="why-choose-one__item__text">{this.state.page_content[8].description}</p> 
                            </div> 
                        </div> 
                    </div> 
                </div>
            </div>
        </div>
    </section>
    );
}

}

export default WhyChooseHome;