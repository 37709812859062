import React, {Component} from 'react';

 
import axios from 'axios'; 
import { BrowserRouter, Routes, Link, Route } from 'react-router-dom'; 
import {api_base_url, base_url} from '../common/Constants'; 
import { SharedContext } from '../pages/createContext';

class MobileMenu extends Component {
    static contextType = SharedContext;
    constructor(){
        super();
        
        this.state = { 
            loading:1, 
            settings:{}

        }
    }

    componentWillMount()
    {  
        
        axios.get(api_base_url+"settings").then(response=>{
            this.setState({settings:response.data}) ;
        });
        const user_id =  localStorage.getItem('user_id');
        axios.get(api_base_url+"categories").then(response=>{
            this.setState({posts:response.data})
            localStorage.setItem('categories',response.data) ;

                if(user_id !== "" && user_id !== null  &&  user_id !== undefined)
                { 
               
                   var data = { user_id : user_id}
                    axios.post(api_base_url+"getuser", data).then(response=>{
                        this.setState({name:response.data.name}); 
 
                       

                    });
                 }
                 const order_session_id =  localStorage.getItem('order_session_id');
                 var data = { order_session_id : order_session_id}
                 axios.post(api_base_url+"cart", data).then(response=>{
                     this.setState({cart:response.data});   
                     this.setState({loading:0});  
                     this.setState({cartload:0});  
                     this.setState({cartcount:response.data.cart.length});  
                 });
        });
        
       
    }

    render()
    {
        
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
  return (
    
     <div></div>
    
  );
}
};

export default MobileMenu;