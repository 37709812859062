import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 

 
class GalleyrContent extends React.Component{

    constructor(){
        super();

        
        this.state = {
            gallery : [],
            loading : 1
        }
        

    }

    componentDidMount()
    {
        axios.get("https://alhuda.integratesolu.com/public/api/gallery").then(response=>{
            this.setState({gallery:response.data}); 
            this.setState({loading:0})
        });
    }


    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
         
            <div className="row gutter-y-30 section-space-top section-space-bottom">

             
                {this.state.gallery.map((blog,key)=>{
 
                    return <div className="col-md-6 col-lg-4">
                    <div className="blog-card wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='000ms'>
                        <div className="blog-card__content"> 
                            <div className="blog-card__image">
                                <img src={blog.image} alt={blog.title} />
                                <Link to="/">
                                <span className="sr-only">{blog.title}</span>  </Link>
                            </div>  
                        </div>  
                    </div> 
                </div>  
                 })}
   

                
            </div>
            
    );
}

}

export default GalleyrContent;