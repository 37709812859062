import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
import Slider from 'react-slick';
 
import axios from 'axios';  
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

 
class SpecialOfferProducts extends React.Component{

    constructor(){
        super();

        
        this.state = {
            products : [],
            loading : 1,
            nav1:null
        }
        

    }

    componentWillMount()
    {
        this.setState({
            nav1: this.slider1, 
          });
        axios.post("https://alhuda.integratesolu.com/public/api/offer_products").then(response=>{
            this.setState({products:response.data}); 
            this.setState({loading:0})
        });
    }


    render()
    { 
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:true,
            autoplaySpeed:2000
          }; 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        <section className={ this.state.products.length == 0 ? "product-one section-space-two dnone" :  "product-one section-space-two" }  id="shop" >
            <div className="container">
                <div className="sec-title sec-title--center">

                    <img src="assets/images/shapes/sec-title-s-1.png" alt="featured products" className="sec-title__img" />
 
                    <h2 className="sec-title__title">Special Offer products</h2> 
                </div> 
                <div className="row gutter-y-30">

                <div className="popular-scroll">
                <Slider
                    {...settings}
                    asNavFor={this.state.nav2}
                    ref={(slider) => (this.slider1 = slider)}
                    >
                    {this.state.products.map((prod, index) => (
                        <div key={prod}>
                        <div className="swiper-slide">
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="product__item wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='00ms'>
                        <div className="product__item__image">
                            <img src={prod.image[0]} alt={prod.title} />
                        </div> 
                        <div className="product__item__content">
                            
                            <h4 className="product__item__title"><Link to={`/products/${prod.slug}`} >{prod.title}</Link></h4> 
                            <div className="product__item__price price-strike"  id={prod.discount > 0 ? "view_old_price" : "hide_old_price"}>{prod.old_price}</div> 
                            <div className="product__item__price">{prod.price}</div> 
                            <Link to={`/products/${prod.slug}`} className="boskery-btn product__item__link">
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__text">View More</span>
                                <i className="icon-meat-3"></i>
                            </Link>
                        </div> 
                    </div> 
                </div>  
                        </div>
                        </div>
                    ))}
                    </Slider>
                    </div>
                {this.state.products.slice(0, 4).map((prod,key)=>{
 
                    return <div className="col-xl-3 col-lg-4 col-sm-6 products-grid">
                    <div className="product__item wow fadeInUp" data-wow-duration='1500ms' data-wow-delay='00ms'>
                        <div className="product__item__image">
                            <img src={prod.image[0]} alt={prod.title} />
                        </div> 
                        <div className="product__item__content">
                            
                            <h4 className="product__item__title"><Link to={`/products/${prod.slug}`} >{prod.title}</Link></h4> 
                            <div className="product__item__price price-strike">{prod.old_price}</div> 
                            <div className="product__item__price">{prod.price}</div> 
                            <Link to={`/products/${prod.slug}`} className="boskery-btn product__item__link">
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__hover"></span>
                                <span className="boskery-btn__text">View More</span>
                                <i className="icon-meat-3"></i>
                            </Link>
                        </div> 
                    </div> 
                </div>  
                 })}
    
                     
                </div> 
            </div> 
            
        </section> 
    );
}

}

export default SpecialOfferProducts;