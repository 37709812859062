import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom';
import AboutHome from '../components/AboutHome';
import PopularProducts from '../components/PopularProducts';
import SpecialOfferProducts from '../components/SpecialOfferProducts';
import BlogsHome from '../components/BlogsHome';
import WhyChooseHome from '../components/WhyChooseHome';

import axios from 'axios'; 
import Header from '../common/Header'; 
import Footer from '../common/Footer';
import Slider from '../common/Slider';
import MobileMenu from '../common/MobileMenu';
import {api_base_url, base_url} from '../common/Constants';
 
class Home extends React.Component{

    constructor(){
        super();
        this.state = {
            categories : [],
            page_content:[],
            loading:1
        }
    }

    componentDidMount()
    {
        axios.get(api_base_url+"categories").then(response=>{
            this.setState({categories:response.data});  
            axios.get(api_base_url+"page_content").then(response=>{
                this.setState({page_content:response.data}); 
                this.setState({loading:0})
            }); 
        }); 
       
    }


    render()
    {
         
        return (
<div>
            <div className="page-wrapper">
      <Header/> 

        <Slider/>
            <section className="gallery-page section-space">
            <div className="container">
                <div className="row gutter-y-20">
                 
                {this.state.categories.map((cat,key)=>{
                    return <div className="col-md-6 col-4 col-lg-2">
                      
                                                    <Link to={`/category/${cat.slug}`} className='cat-icon' style={{backgroundImage: `url(${cat.icon})`}}>  </Link>
                                                     
                                                
                                             </div> 
                 })}
  
                    </div>  
                </div> 
        </section>


        <AboutHome/>
        <SpecialOfferProducts/>
        <PopularProducts/>
        <WhyChooseHome />

        <BlogsHome/>
        <Footer/>
       

      <div className="custom-cursor__cursor"></div>
      <div className="custom-cursor__cursor-two"></div>
      </div>
       <MobileMenu/>
       </div>
      
       
        )
    }

}
export default Home;