import React, { Component } from "react";

class MapClassComponent extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      center: { lat: 25.2747806, lng: 55.3020212 }, // Default location (San Francisco)
      markerPosition: { lat: 25.2747806, lng: 55.3020212 },
      address: "",
      map: null,
      marker: null,
    };
  }

  componentDidMount() {
    // Load Google Maps script
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyABwd_obTGf6j7RJM0lEenJfj7OidZjPqg&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = this.initializeMap;
      document.head.appendChild(script);
    } else {
      this.initializeMap();
    }
  }

  initializeMap = () => {
    const { center } = this.state;

    // Create map
    const map = new window.google.maps.Map(this.mapRef.current, {
      center,
      zoom: 13,
    });

    // Create marker
    const marker = new window.google.maps.Marker({
      position: center,
      map,
      draggable: true,
    });

    // Add event listener for marker drag
    marker.addListener("dragend", this.handleMarkerDrag);

    // Add event listener for map click
    map.addListener("click", this.handleMapClick);

    this.setState({ map, marker });
  };

  handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.updateMarkerAndAddress(lat, lng);
  };

  handleMarkerDrag = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.updateMarkerAndAddress(lat, lng);
  };

  handleSearch = () => {
    const { address, map } = this.state;

    if (!address) {
      alert("Please enter an address.");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        map.setCenter(location);
        this.updateMarkerAndAddress(location.lat(), location.lng());
      } else {
        alert("Geocoding failed: " + status);
      }
    });
  };

  updateMarkerAndAddress = (lat, lng) => {
    const { map, marker } = this.state;

    if (marker) {
      marker.setPosition({ lat, lng });
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results[0]) {
        this.setState({
          markerPosition: { lat, lng },
          address: results[0].formatted_address,
        });
      } else {
        console.error("Reverse geocoding failed:", status);
      }
    });

    map.setCenter({ lat, lng });
  };

  handleAddressChange = (event) => {
    this.setState({ address: event.target.value });
  };

  render() {
    const { markerPosition, address } = this.state;

    return (
      <div>
        {/* Search Bar */}
        <div style={{ marginBottom: "10px" }}>
          <input
            type="text"
            value={address}
            onChange={this.handleAddressChange}
            placeholder="Enter an address"
            style={{ padding: "8px", width: "300px", marginRight: "10px" }}
          />
          <button onClick={this.handleSearch} style={{ padding: "8px 12px" }}>
            Search
          </button>
        </div>

        {/* Selected Location Info */}
        <div style={{ marginBottom: "10px" }}>
          <strong>Selected Location:</strong>
          <p>Latitude: {markerPosition.lat}</p>
          <p>Longitude: {markerPosition.lng}</p>
          <p>Address: {address}</p>
        </div>

        {/* Map */}
        <div
          ref={this.mapRef}
          style={{ height: "500px", width: "100%", border: "1px solid #ddd" }}
        ></div>
      </div>
    );
  }
}

export default MapClassComponent;
