import React, {Component, useState, useEffect} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom';
import SearchContent from '../components/SearchContent'; 
import axios from 'axios'; 
import Header from '../common/Header'; 
import Footer from '../common/Footer';
import PageHeader from '../components/PageHeader'; 
import withRouter from '../components/withRouter';

class Search extends React.Component{

    constructor(){
        super();  
    }
 
    render()
    { 
        return (
            <div>
                <Header/> 
                <PageHeader title="Products" slug={this.props.params.id} />
                <SearchContent slug={this.props.params.id}  />  
                <Footer/>
      
        
        </div>
       
        )
    }

}
export default withRouter(Search);;