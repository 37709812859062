 
import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route, useNavigate } from 'react-router-dom'; 
 
import axios from 'axios';   
import {api_base_url, base_url} from '../../common/Constants';
 
 
class ResetContent extends React.Component{

    constructor(props){
        super(props); 
        this.state = { 
            loading:0,
            error:false,
            password:'',
            cpassword:'',
            error_message : "",
            message:"",
            user:{}
        }
        

    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
      };

      handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
       
          if (!this.state.password ) {
            errors.password = 'Password is required';
          }
          else if (this.state.password.length < 6) {
            errors.password = 'Password should have atleast 6 letters';
          } 
          if (!this.state.cpassword) {
            errors.cpassword = 'Confirm Password is required';
          } 
          else if (this.state.password != this.state.cpassword) {
            errors.password = 'Password & Confirm password should be same';
          } 
    
        if (Object.keys(errors).length > 0) {
          this.setState({ errors });
        } else {
           
            errors.password = "";
            errors.cpassword = ""; 
            this.setState({ errors });
            var data = { user_id:this.state.user.id, password:this.state.password  }
            axios.post(api_base_url+"user/update_password", data).then(response=>{

                if(response.data.error)
                {
                    this.setState({reg_error:true});
                    this.setState({err_message: response.data.error_message});
                    
                }
                else{
                    this.setState({reg_error:false});
                   // localStorage.setItem('user_id',response.data.id) ;
                    this.setState({fopen:true});
                    this.setState({message:"Your password has been updated. You can now login to your account using new password."});
                    
                }
               
                

            }).catch((error) => { 
                  console.log('Conflict error:', error.response);
               
            });

        }
      };
   

    componentDidMount()
    { 
        const errors = {};
        
        errors.password = "";
        errors.cpassword = ""; 
        this.setState({ errors });
        
        var data = {  token : this.props.token }
        axios.post(api_base_url+"user/get_token_user", data).then(response=>{
            
            this.setState({user:response.data.user});
            this.setState({loading:1});
            if(response.data.error)
                {
                    this.setState({error:true});
                    this.setState({error_message:response.data.error_message});
                }
                else{
                    this.setState({error:false});
                    this.setState({error_message:""}); 
                //    this.setState({message:response.data.message});
                }
             
        });
        
    };
 

    render()
    { 
        if (this.state.loading == 0) {
            return <div>Loading...</div>;
          }
    return (
        <section className="login-page">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="login-page__inner forgot-inner"  >
                        <div className="login-page__image wow fadeInLeft animated fadeLeft" data-wow-duration="1500ms" >
                            <img src="../assets/images/about-1.jpg" alt="forgot password"  style={{ maxWidth: '75%' }} />
                        </div> 
                        <div className="login-page__wrap login-page__main-tab-box tabs-box wow fadeInRight animated fadeRight" data-wow-duration="1500ms" >
                            <div className="login-page__wrap__bg"  style={{backgroundImage: `url(assets/images/shapes/login-bg-1.png)`}}  ></div> 
                            <div className="login-page__wrap__top">
                                <div className="login-page__wrap__content">
                                    <h3 className="login-page__title">Reset Password</h3>
                                </div> 
                               
                            </div>
                            <div className="tabs-content">
                                <div className="tab fadeInUp animated active-tab dblock" data-wow-delay="200ms" id="login" >
                                   
                                    <form className="login-page__form">
                                    <div className="login-page__form__input-box">
                                            <input type="password" placeholder="New Password"  name="password" id="password"  
                                                required="" onChange={this.handleChange} value={this.state.password} />
                                            {this.state.errors.password && <label className="error">{this.state.errors.password}</label>}
                                            <span className="icon-padlock"></span>
                                        </div> 
                                        <div className="login-page__form__input-box">
                                            <input type="password" placeholder="Confirm Password"  name="cpassword" id="cpassword"  
                                                required="" onChange={this.handleChange} value={this.state.cpassword} />
                                            {this.state.errors.cpassword && <label className="error">{this.state.errors.cpassword}</label>}
                                            <span className="icon-padlock"></span>
                                        </div> 
                                        
                                       
                                        <div className="login-page__form__input-box login-page__form__input-box--button">
                                            <button type="button" onClick={this.handleSubmit} className="boskery-btn login-page__form__btn">Update Password</button>
                                        </div> 
                                         
                                        <div className='alert alert-success '  id={this.state.fopen ? "success_cart" : "disable_cart"}>{this.state.message}</div>
                        
                        <div className='alert alert-warning '  id={this.state.reg_error ? "success_cart" : "disable_cart"}>{this.state.err_message}</div>
     
                                    </form>  
                                </div> 

                            </div> 
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </section>
    )
    }

}


export function AppWithRouter(props){
    const navigate = useNavigate();
    return  (<ResetContent navigate={navigate} token={props.token}  ></ResetContent>)
}


export default ResetContent;