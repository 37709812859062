import React, {Component, useState, useEffect} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom';
import MapClassComponent from '../components/LocationMapContent'; 
import axios from 'axios'; 
import Header from '../common/Header'; 
import Footer from '../common/Footer';
import PageHeader from '../components/PageHeader'; 
import withRouter from '../components/withRouter';
import {api_base_url, base_url} from '../common/Constants';  


class LocationMap extends React.Component{

    constructor(props){
        super(props);  
        this.state = {
            latitude : 0,
            longitude : 0,
            marker_latitude : 0,
            marker_longitude : 0,
            loading:1
            
        };
    }

    componentWillMount(){

        var order_id = (this.props.params.id - 999)/1000;


        
        if(order_id !== "" && order_id !== null  &&  order_id !== undefined)
            { 
           
               var data = { order_id : order_id}
                axios.post(api_base_url+"get_order_info", data).then(response=>{ 
                     
                    this.setState({latitude:parseFloat(response.data.latitude)});  
                    this.setState({longitude:parseFloat(response.data.longitude)});  
                    this.setState({marker_latitude:parseFloat(response.data.marker_latitude)});  
                    this.setState({marker_longitude:parseFloat(response.data.marker_longitude)});  
                    console.log(response.data.marker_latitude);
                    console.log(response.data.marker_latitude);
                    this.setState({loading:0});
                });
             }
             else{
                this.setState({loading:0});
             }
    }
 
    render()
    { 
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }

        return (
            <div>
                
               
                <MapClassComponent  currentLocation={this.state.currentLocation}  longitude={this.state.marker_longitude}  latitude={this.state.marker_latitude}  />  
            </div>
       
        )
    }

}
export default withRouter(LocationMap);;