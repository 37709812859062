import React, {Component} from 'react';
import { BrowserRouter, Switch, Link, Route } from 'react-router-dom'; 
 
import axios from 'axios'; 

 
class BlogInfoContent extends React.Component{

    constructor(props){
        super(props);

        
        this.state = {
            blogs : [],
            blog_info : [],
            loading : 1 
        }
    }
 
    componentDidMount()
    {
       
            var data = { id : this.props.slug} 
            axios.post("https://alhuda.integratesolu.com/public/api/blog_info", data).then(response=>{
                this.setState({blog_info:response.data}); 
                
                axios.get("https://alhuda.integratesolu.com/public/api/blogs").then(response=>{
                    this.setState({blogs:response.data});
                    this.setState({loading:0});
                });   
        }); 
       
    }
    componentDidUpdate(prevProps)
    {
        
        if (this.props.slug !== prevProps.slug)
        {
            var data = { id : this.props.slug} 
            axios.post("https://alhuda.integratesolu.com/public/api/blog_info", data).then(response=>{
                this.setState({blog_info:response.data}); 
                
                axios.get("https://alhuda.integratesolu.com/public/api/blogs").then(response=>{
                    this.setState({blogs:response.data});
                    this.setState({loading:0});
                });   
        }); 
        }
    }


    render()
    { 
        
        if (this.state.loading == 1) {
            return <div>Loading...</div>;
          }
    return (
        
        <div>
 
    
 <section class="blog-details section-space">
            <div class="container">
                <div class="row gutter-y-60">
                    <div class="col-lg-8">
                        <div class="blog-details">
                            <div class="blog-card blog-card--four wow fadeInUp" data-wow-delay="00ms" data-wow-duration="1500ms">
                                <div class="blog-card__image">
                                    <img src={this.state.blog_info.image} alt="" />
                                    <div class="blog-card__date"><span>{this.state.blog_info.created_date}</span> <span>{this.state.blog_info.created_month}</span></div> 
                                </div> 
                                <div class="blog-card__content">
                                    <ul class="list-unstyled blog-card__meta">
                                        <li><a href="#"><span class="icon-user"></span> by Admin</a></li>
                                         
                                    </ul> 
                                    <h3 class="blog-card__title">{this.state.blog_info.title}</h3>
                                    
                                    <div class="blog-card__content__inner">
                                        <p class="blog-card__text blog-card__text--1"><div dangerouslySetInnerHTML={{ __html: this.state.blog_info.description}} /> </p> 
                                    </div> 
                                </div> 
                            </div> 
                             
                        </div> 
 
                    </div> 
                    <div class="col-lg-4">
                        <div class="sidebar">
                            <aside class="widget-area">
                                 
                                <div class="sidebar__posts-wrapper sidebar__single">
                                    <h4 class="sidebar__title sidebar__posts-title">Latest blogs</h4> 
                                    <ul class="sidebar__posts list-unstyled">
                                   
                                    {this.state.blogs.map((blog, index) => (
                                   
                                   <li class="sidebar__posts__item">
                                            <div class="sidebar__posts__image">
                                            <img src={blog.image} alt={blog.title} />
                                            </div> 
                                            <div class="sidebar__posts__content">
                                               
                                                <h4 class="sidebar__posts__title"><Link to={`/blog-info/${blog.slug}`} >{blog.title}</Link></h4>
                                                <p class="sidebar__posts__meta"><a href="#">
                                                        <span class="icon-user"></span>
                                                        By Admin</a></p>  
                                            </div> 
                                        </li> 
                                    ))}
                                    </ul> 
                                </div> 
                                
                            </aside>
                        </div> 
                    </div> 
                </div> 
            </div> 
        </section> 

        </div>
    );
}

}

export default BlogInfoContent;