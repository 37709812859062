import React, { Component } from "react";

class LocationMapComponent extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      center: { lat: this.props.latitude, lng: this.props.longitude }, // Default location (San Francisco)
      markerPosition: {lat: this.props.latitude, lng: this.props.longitude},
      address: "",
      map: null,
      marker: null,
    };
  }

  componentDidMount() {
    // Load Google Maps script
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyABwd_obTGf6j7RJM0lEenJfj7OidZjPqg&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = this.initializeMap;
      document.head.appendChild(script);
    } else {
      this.initializeMap();
    }
  }

  initializeMap = () => {
    const { center } = this.state;

    // Create map
    const map = new window.google.maps.Map(this.mapRef.current, {
      center,
      zoom: 17,
    });

    // Create marker
    const marker = new window.google.maps.Marker({
      position: center,
      map,
      draggable: true,
    });
 
    this.setState({ map, marker });
  };

  handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.updateMarkerAndAddress(lat, lng); 
  };

  handleMarkerDrag = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.updateMarkerAndAddress(lat, lng); 
  };

  

  render() {
    const { markerPosition, address } = this.state;

    return (
      <div>
        
        <div style={{ marginBottom: "10px" }}> 
         
        </div>

        {/* Map */}
        <div
          ref={this.mapRef}
          style={{ height: "500px", width: "100%", border: "1px solid #ddd" }}
        ></div>
      </div>
    );
  }
}

export default LocationMapComponent;
